<template>
  <div v-loading="loading">
    <h1 class="text-xl mb-8 mt-12">Sendgrid Activities</h1>
    <EmailSengridEvents :sendgridActivities="recipientSendgridEvents" />
  </div>
</template>

<script>
import { communicationMapActions } from "@/store/modules/communication";
export default {
  name: "single-recipient-sendgrid-activities",
  components: {
    EmailSengridEvents: () => import("./EmailSendgridEvents.vue")
  },
  props: {
    communicationId: {
      type: String
    }
  },
  mounted() {
    this.queryCommunicationRecipients(this.communicationId);
  },
  data() {
    return {
      loading: false,
      recipients: [],
      activitiesData: {
        activities: []
      }
    };
  },
  methods: {
    ...communicationMapActions(["getCommunicationRecipients"]),
    async queryCommunicationRecipients(communicationId, queryData) {
      try {
        this.loading = true;
        const response = await this.getCommunicationRecipients({
          id: communicationId,
          query: queryData
        });
        this.activitiesData = response.data;
      } catch (error) {
        this.$appNotifyError("Failed to fetch communication recipients data");
        this.$bugSnagClient.notify(error);
      } finally {
        this.loading = false;
      }
    }
  },
  computed: {
    recipientSendgridEvents() {
      if (
        this.activitiesData &&
        this.activitiesData.activities &&
        this.activitiesData.activities.length > 0
      ) {
        return this.activitiesData.activities[0].events;
      }
      return [];
    }
  }
};
</script>

<style></style>
